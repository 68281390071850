<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">{{ command.name }}</div>
    <Form :model="form" :label-width="180">
      <FormItem :label="showLang('com.pswd.confirm')" v-if="needCheck">
        <div v-if="needCheck">
          <Input v-model="form.pswd" type="password"  ref="pswd"  style="width: 220px;height:32px;">
            <Button slot="append" icon="ios-eye" @click="eyeOutline()"></Button>
          </Input>
          <!-- <Icon type="ios-eye-outline"  @click="eyeOutline()"></Icon> -->
          <span style="color:red;margin-left:20px">*** {{ showLang('com.pswd.user.confirm') }} </span>
        </div>
        <div v-else>{{ showLang('com.pswd.lifespan') }}</div>
      </FormItem>
      <FormItem :label="showLang('com.but.gis.set')" v-if="['setLatLng'].includes(command.code)" class="form-item">
        系统配置灯控所在位置或安装的灯杆位置
      </FormItem>
      <FormItem :label="showLang('com.tab.slogan')" v-if="['setComArgs', 'getComArgs'].includes(command.code)" class="form-item">
        <Select v-model="form.comname">
          <template v-for="(v, k) in coms8088names">
            <Option :key="k" :value="parseInt(k)">{{ v }}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.comm.method')" v-if="['setComArgs'].includes(command.code)" class="form-item">
        <Select v-model="form.comtype">
          <template v-for="(v, k) in com8088types">
            <Option :key="k" :value="parseInt(k)">{{ v }}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.BAUD')" v-if="['setComArgs'].includes(command.code)" class="form-item">
        <Select v-model="form.combuad">
          <template v-for="(v, k) in com8088buads">
            <Option :key="k" :value="parseInt(k)">{{ v }}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.data.bits')" v-if="['setComArgs'].includes(command.code)" class="form-item">
        <Select v-model="form.comdata">
          <template v-for="(v, k) in com8088datas">
            <Option :key="k" :value="parseInt(k)">{{ v }}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.check.bit')" v-if="['setComArgs'].includes(command.code)" class="form-item">
        <Select v-model="form.comparity">
          <template v-for="(v, k) in com8088paritys">
            <Option :key="k" :value="parseInt(k)">{{ v }}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.stop.bit')" v-if="['setComArgs'].includes(command.code)" class="form-item">
        <Select v-model="form.comstop">
          <template v-for="(v, k) in com8088stops">
            <Option :key="k" :value="parseInt(k)">{{ v }}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.minor.leakage.val')" v-if="['setLeakageRate'].includes(command.code)">
        <Input v-model="form.ll" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.severe.leakage.val')" v-if="['setLeakageRate'].includes(command.code)">
        <Input v-model="form.lh" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.threshold.leakage.val')" v-if="['setLeakageRate'].includes(command.code)">
        <Input v-model="form.lx" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.tab.ip.http')" v-if="['setNetwork', 'ftp'].includes(command.code)">
        <Input v-model="form.host" type="text" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.ins.port')" v-if="['setNetwork', 'ftp'].includes(command.code)">
        <Input v-model="form.port" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.ins.heartbeat')" v-if="['setNetwork'].includes(command.code)">
        <Input v-model="form.heart" type="number" style="width: 120px;height:32px;"><span slot="append">s</span></Input>
      </FormItem>
      <FormItem :label="showLang('com.ins.ch')" v-if="['getChipPower', 'getChipCurrent', 'getChipVoltage', 'adjustChipPower', 'adjustChipCurrent', 'adjustChipVoltage', 'adjustChipBase', 'getSigFlag', 'setSigFlag', 'setLoraWork', 'getLatLngArgs', 'setLatLngArgs', 'setPlanType', 'getPlanType', 'setOutputPowerLimit', 'getOutputPowerLimit', 'setOutputVoltageCompensation', 'getOutputVoltageCompensation'].includes(command.code)">
        <Input v-model="form.channel" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="最小值" v-if="['setOutputPowerLimit'].includes(command.code)">
        <Input v-model="form.min" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="最大值" v-if="['setOutputPowerLimit'].includes(command.code)">
        <Input v-model="form.max" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.is.enable')" v-if="['setLatLngArgs'].includes(command.code)">
        <i-switch v-model="form.enable" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
          <span slot="open">{{ showLang('com.state.enable') }}</span>
          <span slot="close">{{ showLang('com.state.disable') }}</span>
        </i-switch>
      </FormItem>
      <template v-if="['setLatLngArgs'].includes(command.code)">
        <FormItem v-for="(item, idx) in form.gisControlList" :key="idx" :label="showLang('com.date.time') + (idx + 1) + (idx < 3 ? '[日升]' : '[日落]')">
          <div style="display: flex">
            <Input v-model="item.bright" type="number" number style="width: 200px; height: 32px; margin-right: 10px;">
              <span slot="prepend">{{ showLang('com.lighting.op') }}</span>
              <span slot="append">%[0-100]</span>
            </Input>
            <Input v-model="item.color" type="number" number style="width: 200px; height: 32px; margin-right: 10px;">
              <span slot="prepend">{{ showLang('com.lighting.oc') }}</span>
              <span slot="append">%[0-100]</span>
            </Input>
            <Input v-model="item.delay" type="number" number style="width: 300px; height: 32px; margin-right: 10px;">
              <span slot="prepend">{{ showLang('com.date.delayed') }}</span>
              <span slot="append">{{ showLang('unit.time.min') }}[-1200-1200]</span>
            </Input>
          </div>
        </FormItem>
      </template>
      <FormItem :label="showLang('com.look.data')" v-if="['getYearPlan'].includes(command.code)">
        <div style="display: flex;align-items:center;">
          <DatePicker type="date" v-model="form.date" format="yyyy-MM-dd"></DatePicker>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.time.delay')" v-if="['handControl'].includes(command.code)">
        <div v-if="form.expire" style="font-size: 40px; margin: 20px">
          {{ new Date(form.expire).format('yyyy-MM-dd HH:mm:ss') }}
        </div>
        <div style="display: flex;align-items:center;">
          <i-switch v-model="form.open" size="large" style="margin:3px 10px;" true-color="red" false-color="gray">
            <span slot="open">{{ showLang('com.but.open.lamp') }}</span>
            <span slot="close">{{ showLang('com.but.close.lamp') }}</span>
          </i-switch>
          <DatePicker type="datetime" v-model="form.expire" style="width: 170px" format="yyyy-MM-dd HH:mm:ss"></DatePicker>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.tab.size.pack')" v-if="['update'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.packLen" type="number" number style="width: 120px; height: 32px">
            <span slot="append">{{ showLang('com.unit.byte') }}</span>
          </Input>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.but.upgrade.file')" v-if="['update'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <div class="logo-upload">
            <input ref="fileInput" type="file" accept=".bin" :disabled="loading" />
          </div>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.ins.alarm.time')" v-if="['setAlarmRange'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.interval" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">min</span>
          </Input>
          <!-- <Slider v-model="form.interval" :min="10" :max="1440" style="flex:auto;margin: 0 15px"></Slider> -->
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.V.max')" v-if="['setAlarmRange'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.vhigh" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">V</span>
          </Input>
          <!-- <Slider v-model="form.vhigh" :min="0" :max="500" style="flex:auto;margin: 0 15px"></Slider> -->
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.V.min')" v-if="['setAlarmRange'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.vlow" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">V</span>
          </Input>
          <!-- <Slider v-model="form.vlow" :min="0" :max="220" style="flex:auto;margin: 0 15px"></Slider> -->
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.I.max')" v-if="['setAlarmRange'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.chigh" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">A</span>
          </Input>
          <!-- <Slider v-model="form.chigh" :min="0" :max="20" style="flex:auto;margin: 0 15px"></Slider> -->
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.I.min')" v-if="['setAlarmRange'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.clow" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">A</span>
          </Input>
          <!-- <Slider v-model="form.clow" :min="0" :max="20" style="flex:auto;margin: 0 15px"></Slider> -->
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.T.max')" v-if="['setAlarmRange'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.thigh" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">℃</span>
          </Input>
          <!-- <Slider v-model="form.thigh" :min="0" :max="100" style="flex:auto;margin: 0 15px"></Slider> -->
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.T.min')" v-if="['setAlarmRange'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.tlow" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">℃</span>
          </Input>
          <!-- <Slider v-model="form.tlow" :min="0" :max="100" style="flex:auto;margin: 0 15px"></Slider> -->
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.lea.cur')" v-if="['setAlarmRange'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.leac" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">mA</span>
          </Input>
          <!-- <Slider v-model="form.tlow" :min="0" :max="100" style="flex:auto;margin: 0 15px"></Slider> -->
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.lea.vol')" v-if="['setAlarmRange'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.leav" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">V</span>
          </Input>
          <!-- <Slider v-model="form.tlow" :min="0" :max="100" style="flex:auto;margin: 0 15px"></Slider> -->
        </div>
      </FormItem>
      <FormItem :label="showLang('com.state.is.v.police')" v-if="['setAlarmStatus'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.voltageAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">{{ showLang('com.state.enable') }}</span>
            <span slot="close">{{ showLang('com.state.disable') }}</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.state.is.i.police')" v-if="['setAlarmStatus'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.currentAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">{{ showLang('com.state.enable') }}</span>
            <span slot="close">{{ showLang('com.state.disable') }}</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.state.is.t.police')" v-if="['setAlarmStatus'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.tempAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">{{ showLang('com.state.enable') }}</span>
            <span slot="close">{{ showLang('com.state.disable') }}</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.state.is.gyro.police')" v-if="['setAlarmStatus'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.gyroAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">{{ showLang('com.state.enable') }}</span>
            <span slot="close">{{ showLang('com.state.disable') }}</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.state.is.leac.police')" v-if="['setAlarmStatus'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.leacAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">{{ showLang('com.state.enable') }}</span>
            <span slot="close">{{ showLang('com.state.disable') }}</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.state.is.leav.police')" v-if="['setAlarmStatus'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.leavAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">{{ showLang('com.state.enable') }}</span>
            <span slot="close">{{ showLang('com.state.disable') }}</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.state.is.leak.police')" v-if="['setLeakageAlarmEnable'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.leakageAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">{{ showLang('com.state.enable') }}</span>
            <span slot="close">{{ showLang('com.state.disable') }}</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.ins.ch') + 1 + showLang('com.date.delayed')" v-if="['setUnionParam'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.keep1" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">S</span>
          </Input>
          <Slider v-model="form.keep1" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.ins.ch') + 2 + showLang('com.date.delayed')" v-if="['setUnionParam'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.keep2" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">S</span>
          </Input>
          <Slider v-model="form.keep2" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.is.sensing')" v-if="['setUnionParam'].includes(command.code)" class="form-item">
        <Select v-model="form.sensor">
          <Option :value="1">{{ showLang('com.state.effective') }}</Option>
          <Option :value="2">{{ showLang('com.state.invalid') }}</Option>
          <Option :value="0">{{ showLang('com.state.no.modify') }}</Option>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.is.signal')" v-if="['setUnionParam'].includes(command.code)" class="form-item">
        <Select v-model="form.txen">
          <Option :value="128">{{ showLang('com.state.effective') }}</Option>
          <Option :value="2">{{ showLang('com.state.invalid') }}</Option>
          <Option :value="0">{{ showLang('com.state.no.modify') }}</Option>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.sending.time')" v-if="['setUnionParam'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.period" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">S</span>
          </Input>
          <Slider v-model="form.period" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.relay.level')" v-if="['setRelayLevel'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.level" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <!-- <span slot="append">S</span> -->
          </Input>
          <Slider v-model="form.level" :min="1" :max="15" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.linkage.number')" v-if="['setUnionParam'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.rfsn" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.rfsn" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="'433' + showLang('com.lighting.P')" v-if="['setUnionParam'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.txpower" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.txpower" :min="0" :max="8" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="command.code == 'setUnionParam' ? '433' + showLang('com.lighting.channel2') : showLang('cmd.content.channel')" v-if="['setUnionParam', 'setLoraChannel'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.channel" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.channel" :min="0" :max="100" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.number.range')" v-if="['setUnionParam'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.range" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.range" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.is.put.signal2')" v-if="['setUnionParam'].includes(command.code)" class="form-item">
        <Select v-model="form.rxen">
          <Option :value="1">{{ showLang('com.op.yes') }}</Option>
          <Option :value="2">{{ showLang('com.op.deny') }}</Option>
          <Option :value="0">{{ showLang('com.state.no.modify') }}</Option>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.linkage.mode')" v-if="['setUnionParam'].includes(command.code)" class="form-item">
        <Select v-model="form.mode">
          <Option :value="1">{{ showLang('com.lighting.Linkage.control') }}</Option>
          <Option :value="2">{{ showLang('com.lighting.lamp.contact') }}</Option>
          <Option :value="0">{{ showLang('com.state.no.modify') }}</Option>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.repeat.num')" v-if="['setUnionParam'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.repeat" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.repeat" :min="0" :max="10" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.lighting.lamp1')" v-if="['set433Args'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.delay1" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">S</span>
          </Input>
          <Slider v-model="form.delay1" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.lighting.lamp2')" v-if="['set433Args'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.delay2" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">S</span>
          </Input>
          <Slider v-model="form.delay2" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.sensor.detection')" v-if="['set433Args'].includes(command.code)" class="form-item">
        <Select v-model="form.senseEn">
          <Option :value="1">{{ showLang('com.state.allow') }}</Option>
          <Option :value="0">{{ showLang('com.state.prohibit') }}</Option>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.sensitivity.detection')" v-if="['set433Args'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.senseLevel" type="number" number style="width: 120px;height:32px;">
            <span slot="append">S</span>
          </Input>
          <Slider v-model="form.senseLevel" :min="1" :max="10" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.is.detection')" v-if="['set433Args'].includes(command.code)" class="form-item">
        <Select v-model="form.txReqEn">
          <Option :value="1">{{ showLang('com.state.allow') }}</Option>
          <Option :value="0">{{ showLang('com.state.prohibit') }}</Option>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.is.detection.num')" v-if="['set433Args'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.txReqCnt" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.txReqCnt" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.sending.cycle')" v-if="['set433Args'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.txDuty" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">S</span>
          </Input>
          <Slider v-model="form.txDuty" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.sending.responding')" v-if="['set433Args'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.txSleep" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <span slot="append">S</span>
          </Input>
          <Slider v-model="form.txSleep" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.is.put.signal2')" v-if="['set433Args'].includes(command.code)" class="form-item">
        <Select v-model="form.rxReqEn">
          <Option :value="1">{{ showLang('com.state.allow') }}</Option>
          <Option :value="0">{{ showLang('com.state.prohibit') }}</Option>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.send.power')" v-if="['set433Args'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.txSleep" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.txSleep" :min="1" :max="8" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('cmd.content.channel')" v-if="['set433Args'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.channel" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.channel" :min="1" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.but.433.id.set')" v-if="['set433Id'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.id433" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.id433" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.but.Sender.id')" v-if="['send433Adjust'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.id433" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.id433" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.lighting.Control.range')" v-if="['set433Args', 'send433Adjust'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.range" type="number" number style="width: 120px;height:32px;">
            <!-- <span slot="prepend">http://</span> -->
            <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.range" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.but.sigle.ontime')" v-if="['setSigFlag'].includes(command.code)">
        <Input v-model="form.sigleOn" type="number" style="width: 120px;height:32px;"></Input>单位500MS，1指500ms，2指1000ms...
      </FormItem>
      <FormItem :label="showLang('com.but.sigle.offtime')" v-if="['setSigFlag'].includes(command.code)"> 
        <Input v-model="form.sigleOff" type="number" style="width: 120px;height:32px;"></Input>单位500MS，1指500ms，2指1000ms... 
      </FormItem>
      <FormItem label="ON亮度" v-if="['setSigFlag'].includes(command.code)">
        <Input v-model="form.sigleOnBright" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="Off亮度" v-if="['setSigFlag'].includes(command.code)"> 
        <Input v-model="form.sigleOffBright" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.date.check')" v-if="['setLeakageAlarmEnable'].includes(command.code)">
        <Input v-model="form.checkTime" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.ins.record.num')" v-if="['setLeakageAlarmEnable'].includes(command.code)">
        <Input v-model="form.uploadCount" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.V')" v-if="['adjustChipBase', 'adjustChipVoltage', 'setOutputVoltageCompensation'].includes(command.code)">
        <Input v-model="form.voltage" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.I')" v-if="['adjustChipBase', 'adjustChipCurrent'].includes(command.code)">
        <Input v-model="form.current" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.P')" v-if="['adjustChipBase', 'adjustChipPower'].includes(command.code)">
        <Input v-model="form.power" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.op.select.group')" v-if="['handGroup'].includes(command.code)" class="form-item">
        <Select v-model="form.areaId" style="width: 220px;" @on-change="areaChanged">
          <template v-for="(s, idx) in areas">
            <Option :value="s.id" :key="idx">{{ s.name }}[{{ s.code }}]</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.op') + '1'" v-if="['handSingle', 'handGroup', 'handBright'].includes(command.code)">
        <Input v-model="form.outputPower1" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.op') + '2'" v-if="['handSingle', 'handGroup', 'handBright'].includes(command.code)">
        <Input v-model="form.outputPower2" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.op') + '3'" v-if="['handSingle', 'handGroup', 'handBright'].includes(command.code)">
        <Input v-model="form.outputPower3" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.op') + '4'" v-if="['handSingle', 'handGroup', 'handBright'].includes(command.code)">
        <Input v-model="form.outputPower4" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.oc') + '1'" v-if="['handSingle', 'handGroup', 'handColor'].includes(command.code)">
        <Input v-model="form.outputColor1" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.oc') + '2'" v-if="['handSingle', 'handGroup', 'handColor'].includes(command.code)">
        <Input v-model="form.outputColor2" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.oc') + '3'" v-if="['handSingle', 'handGroup', 'handColor'].includes(command.code)">
        <Input v-model="form.outputColor3" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.oc') + '4'" v-if="['handSingle', 'handGroup', 'handColor'].includes(command.code)">
        <Input v-model="form.outputColor4" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.time.delay')" v-if="['handSingle', 'openSingle', 'closeSingle', 'handGroup', 'handBright', 'handColor'].includes(command.code)">
        <div v-if="form.expire" style="font-size: 40px; margin: 20px">
          {{ new Date(form.expire).format('yyyy-MM-dd HH:mm:ss') }}
        </div>
        <div style="display: flex;align-items:center;">
          <!-- <i-switch v-model="form.open" size="large" style="margin:3px 10px;" true-color="red" false-color="gray">
            <span slot="open">开灯</span>
            <span slot="close">关灯</span>
          </i-switch> -->
          <DatePicker type="datetime" v-model="form.expire" style="width: 170px" format="yyyy-MM-dd HH:mm:ss"></DatePicker>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.ins.timeTable')" v-if="['setDayPlan1'].includes(command.code)" class="form-item">
        <div class="flex-row">
          <Select v-model="form.timeId" style="width: 300px" class="mr10">
            <template v-for="item in timeTables">
              <Option v-if="item.type == showLang('com.but.timing')" :key="item.id" :value="item.id">[{{ item.type }}]{{ item.name }}</Option>
            </template>
          </Select>
          <Button type="primary" v-if="form.timeId > 0 && funCodes('tle')" @click="editDayPlan6">编辑日表</Button>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.ins.timeTable')" v-if="['setDayPlan2'].includes(command.code)" class="form-item">
        <div class="flex-row">
          <Select v-model="form.timeId" style="width: 300px" class="mr10">
            <template v-for="item in timeTables">
              <Option v-if="item.type == showLang('com.plan.light.time')" :key="item.id" :value="item.id">[{{ item.type }}]{{ item.name }}</Option>
            </template>
          </Select>
          <Button type="primary" v-if="form.timeId > 0 && funCodes('tle')" @click="editDayPlan7">编辑日表</Button>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.but.tableType')" v-if="['setPlanType'].includes(command.code)" class="form-item">
        <RadioGroup v-model="form.planType">
          <Radio v-for="(v, k) in lightControlMode" :key="k" :label="parseInt(k)">{{ showLang(v) }}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="电压输出参数" v-if="['setVoltageOutputArgs'].includes(command.code)" class="form-item">
        <RadioGroup v-model="form.voltageOutputArgs">
          <Radio v-for="(v, k) in voltageOutputArgs" :key="k" :label="parseInt(k)" style="margin-right: 15px">{{ v }}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="PWM极性" v-if="['setPWMOutputArgs'].includes(command.code)" class="form-item">
        <RadioGroup v-model="form.pwmPolar">
          <Radio v-for="(v, k) in pwmPolar" :key="k" :label="parseInt(k)" style="margin-right: 15px">{{ v }}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="PWM输出频率" v-if="['setPWMOutputArgs'].includes(command.code)" class="form-item">
        <RadioGroup v-model="form.pwmFrequence">
          <Radio v-for="(v, k) in pwmFrequence" :key="k" :label="parseInt(k)" style="margin-right: 15px">{{ v }}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem :label="showLang('com.ins.light.val')" v-if="['setLuxOpenValue'].includes(command.code)" class="form-item">
        <Input v-model="form.lux" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="append">字节</span> -->
        </Input>
      </FormItem>
      <FormItem :label="showLang('com.but.file.path')" v-if="['ftp'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.file" type="text" style="width: 220px; height: 32px"> </Input>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.login.account')" v-if="['ftp'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.user" type="text" style="width: 220px; height: 32px"> </Input>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.but.ftp.pass')" v-if="['ftp'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.pswd1" type="text" style="width: 220px; height: 32px"> </Input>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.tab.size.file')" v-if="['ftp'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.len" type="number" number style="width: 220px; height: 32px">
            <span slot="append">{{ showLang('com.unit.byte') }}</span>
          </Input>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.check.word')" v-if="['ftp'].includes(command.code)" class="form-item">
        <div style="display: flex">
          <Input v-model="form.crc" type="text" style="width: 220px; height: 32px"> </Input>
        </div>
      </FormItem>
      <FormItem label="APN" v-if="['setAPN'].includes(command.code)">
        <Input v-model="form.apnUrl" style="width: 200px;height:32px;"></Input>
      </FormItem>
      <FormItem label="用户" v-if="['setAPN'].includes(command.code)">
        <Input v-model="form.apnUser" style="width: 200px;height:32px;"></Input>
      </FormItem>
      <FormItem label="密码" v-if="['setAPN'].includes(command.code)">
        <Input v-model="form.apnPswd" style="width: 200px;height:32px;"></Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <ModalLightTable6 v-model="showEditModal6" :item="editItem" @saved="getUseablePlan" />
      <ModalLightTable7 v-model="showEditModal7" :item="editItem" @saved="getUseablePlan" />
      <Button style="margin-right: 8px" @click="cancel">{{ showLang('com.op.cancel') }}</Button>
      <Button type="primary" @click="ok">{{ showLang('com.lighting.sending') }}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalLightTable6 from '@/components/config/timetable/ltime6/ModalLightTable'
import ModalLightTable7 from '@/components/config/timetable/ltime7/ModalLightTable'
export default {
  name: 'ModalArgEdit',
  components: {
    ModalLightTable6,
    ModalLightTable7,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    command: {
      type: Object,
      default() {
        return {}
      }
    },
    areas: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      showModal: this.value,
      showEditModal6: false,
      showEditModal7: false,
      editItem: {},
      loading: false,
      events: {},
      timeTables: [],
      form: {
        apnUrl: '',
        apnUser: '',
        apnPswd: '',
        areaId: 0, //单灯分组ID
        packLen: 512, //OTA升级包大小
        content: null, //OTA文件内容
        pswd: '', //录入密码
        single: true, //显示单日或整月
        date: '', //日期
        expire: '', //过期时间
        open: true, //开关灯
        currentRate: 1000, //设置电流倍率
        timeId: 0, //时间表
        planType: 0, //日表模式
        groupNo: 0, //分组编号
        outputPower1: 0, //调光亮度1
        outputPower2: 0, //调光亮度2
        outputColor1: 0, //调光色温1
        outputColor2: 0, //调光色温2
        outputPower3: 0, //调光亮度3
        outputPower4: 0, //调光亮度4
        outputColor3: 0, //调光色温3
        outputColor4: 0, //调光色温4
        host: '120.79.171.110', //ip或域名
        port: 9996, //端口
        heart: 60, //心跳时间，单位秒
        ll: 30, //轻微漏电阈值
        lh: 100, //严重漏电阈值
        lx: 2000, //跳闸漏电阈值
        channel: 1, //通道
        speed: 7, //速率，默认7
        comname: 1,
        comtype: 1,
        combuad: 6,
        comdata: 8,
        comparity: 0,
        comstop: 1,
        lux: 100, //光感值
        // ip: '',
        // port: 21,
        // ipport: '211.162.119.217:20010',
        file: 'bin/App8051v515.bin',
        user: 'amdm',
        pswd1: '',
        len: 109492,
        crc: '9CDD',
        voltageAlarm: false, //电压是否报警
        currentAlarm: false, //电流是否报警
        tempAlarm: false, //温度是否报警
        gyroAlarm: false, //倾斜是否报警
        leacAlarm: false, //漏电电流是否报警
        leavAlarm: false, //漏电电压是否报警
        leakageAlarm: false, //漏电是否报警
        checkTime: 15, //检查时间
        uploadCount: 1, //上报次数
        rfsn: 1, //联动模块编号
        // channel: 0, //433应用频道
        txpower: 3, //433发送功率
        txen: 128, //是否发送433信号,对于检测方可以发送，其他的只能RX
        repeat: 1, //重发次数 0~10有效，默认2
        period: 3, //重发周期
        range: 5, //响应范围，这个对发送方无意义，对接收方有用
        rxen: 1, //是否接收433联动信号  1=允许接收
        mode: 2, //联动方式， 1=按Range ,2:全局，Range无效
        keep1: 10, //灯1延时时间（秒）
        keep2: 1, //灯2延时时间（秒）
        sensor: 1, //是否启用感应启用，RX方只管自己
        voltage: 220, //计量芯片校准电压
        current: 1, //计量芯片校准电流
        power: 100, //计量芯片校准功率
        interval: 60, //报警间隔时间，单位分钟
        vhigh: 280, //电压上限值
        vlow: 80, //电压下限值
        chigh: 10, //电流上限值
        clow: 0.3, //电流下限值
        thigh: 80, //温度上限值
        tlow: 50, //温度下限值
        leac: 500, //漏电电流
        leav: 100, //漏电电压
        delay1: 10, //1路亮灯延时，单位秒，最大255
        delay2: 10, //2路亮灯延时，单位秒，最大255
        senseEn: 1, //是否允许传感器检测，为1允许
        senseLevel: 3, //检测灵敏度，单位秒，范围1-10
        txReqEn: 1, //检测到后是否允许发送，为1允许
        txReqCnt: 3, //检测到后发送数量
        txDuty: 3, //发送周期
        txSleep: 3, //发送不响应期
        rxReqEn: 1, //是否允许接收信号，为1允许
        txPower: 4, //发送功率（1-8）
        // channel: 0, //信道
        // range: 0, //控制的范围
        id433: 3, //发送方ID
        level: 5, //中继级别
        sigleOn: 1, //信号灯开时间，单位500MS
        sigleOff: 1, //信号灯关时间，单位500MS
        gisControlList: [
          //经纬度控制参数列表
          { bright: 0, color: 100, delay: 0 },
          { bright: 0, color: 100, delay: 0 },
          { bright: 0, color: 100, delay: 0 },
          { bright: 100, color: 0, delay: 0 },
          { bright: 100, color: 0, delay: 0 },
          { bright: 100, color: 0, delay: 0 }
        ],
        voltageOutputArgs: 0, //0-10V电压控制参数
        pwmPolar: 0, //PWM 极性
        pwmFrequence: 0,//PWM输出频率
        min: 0,
        max: 50,
        sigleOnBright: 100,
        sigleOffBright: 30,
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['needCheckPswd', 'showLang', 'funCodes']),
    ...mapState('group', ['groups', 'selectedNode', 'selectedGroups', 'rootTitle']),
    ...mapState('common', [
      'pwmPolar', 'pwmFrequence', 'voltageOutputArgs', 'lightControlMode', 
      'deviceTypes', 'productCodes', 'weeks1', 'ampm', 
      'coms8088names', 'com8088types', 'com8088buads', 'com8088datas', 'com8088paritys', 'com8088stops'
    ]),
    needCheck: function() {
      return this.needCheckPswd && this.command.pswd
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal
      if (newVal) {
        // console.log('args modal show', this.command)
        this.form.content = null
        this.form.packLen = 512;
        this.form.apnUrl = '';
        this.form.apnUser = '';
        this.form.apnPswd = '';
        switch (this.command.code) {
          case 'setEventEnable': {
            this.getEvents()
            break
          }
          case 'getYearPlan': {
            this.form.single = this.command.args.single
            this.form.date = this.command.args.date
            break
          }
          case 'handControl': {
            this.form.open = this.command.args.open
            this.form.expire = this.command.args.expire
            break
          }
          case 'setCurrentRate': {
            this.form.currentRate = this.command.args.currentRate
            break
          }
          case 'setLeakageRate': {
            this.form.ll = this.command.args.ll
            this.form.lh = this.command.args.lh
            this.form.lx = this.command.args.lx
            break
          }
          case 'handBright':
          case 'handColor':
          case 'handSingle':
          case 'closeSingle':
          case 'openSingle': {
            // this.form.open = this.command.args.open;
            let d = new Date()
            d.setHours(d.getHours() + 1)
            this.form.expire = d.format('yyyy-MM-dd HH:mm:ss')
            this.form.groupNo = 1
            break
          }
          case 'handGroup': {
            let d = new Date()
            d.setHours(d.getHours() + 1)
            this.form.expire = d.format('yyyy-MM-dd HH:mm:ss')
            this.form.groupNo = 1
            this.form.areaId = 0
            break
          }
          case 'setDayPlan1':
          case 'setDayPlan2': {
            this.form.timeId = 0
            this.getUseablePlan()
            break
          }
        }
        // console.log('show command', this.command)
      }
    },
    showModal(val) {
      this.$emit('showChanged', val)
    }
  },
  methods: {
    editDayPlan6: function(){
      let rows = this.timeTables.filter(p => p.id == this.form.timeId);
      if(rows.length == 0)return;
      this.editItem = { isAdd: false, index: 0, data: rows[0] };
      this.showEditModal6 = true;
    },
    editDayPlan7: function(){
      let rows = this.timeTables.filter(p => p.id == this.form.timeId);
      if(rows.length == 0)return;
      this.editItem = { isAdd: false, index: 0, data: rows[0] };
      this.showEditModal7 = true;
    },
    eyeOutline() {
      this.$refs.pswd.type = 'text'
      setTimeout(() => {
        if (this.$refs.pswd) {
          this.$refs.pswd.type = 'password'
        }
      }, 3000)
      console.log('eyeOutline')
    },
    areaChanged: function() {
      let ass = this.areas.filter(p => p.id == this.form.areaId)
      if (ass.length == 0) return
      this.form.groupNo = ass[0].code
      this.form.outputPower1 = ass[0].bright
      this.form.outputPower2 = ass[0].bright
      this.form.outputPower3 = ass[0].bright
      this.form.outputPower4 = ass[0].bright
      this.form.outputColor1 = ass[0].color
      this.form.outputColor2 = ass[0].color
      this.form.outputColor3 = ass[0].color
      this.form.outputColor4 = ass[0].color
    },
    getUseablePlan: function() {
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLightPlan`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'timeTables', res.data)
        }
      })
    },
    getEvents: function() {
      this.$axios.post(`//${this.domains.trans}/station/enum/BJ8051Events`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'events', res.data)
        }
      })
    },
    createCmd: function() {
      let args
      if (this.command.code == 'openSingle') {
        let d = new Date(this.form.expire)
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0)
        args = { bright1: 100, bright2: 100,bright3: 100, bright4: 100,  color1: 100, color2: 100, color3: 100, color4: 100, expire: expire }
      } else if (this.command.code == 'closeSingle') {
        let d = new Date(this.form.expire)
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0)
        args = { bright1: 0, bright2: 0, color1: 0, color2: 0,bright3: 0, bright4: 0, color3: 0, color4: 0, expire: expire }
      } else if (this.command.code == 'handSingle') {
        let d = new Date(this.form.expire)
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0)
        args = {
           bright1: this.form.outputPower1, bright2: this.form.outputPower2,
           bright3: this.form.outputPower3, bright4: this.form.outputPower4,
            color1: this.form.outputColor1, color2: this.form.outputColor2, 
            color3: this.form.outputColor3, color4: this.form.outputColor4, 
            expire: expire }
      } else if (this.command.code == 'handBright') {
        let d = new Date(this.form.expire)
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0)
        args = {
           bright1: this.form.outputPower1, bright2: this.form.outputPower2, 
           bright3: this.form.outputPower3, bright4: this.form.outputPower4, 
           expire: expire }
      } else if (this.command.code == 'handColor') {
        let d = new Date(this.form.expire)
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0)
        args = { 
          color1: this.form.outputColor1, color2: this.form.outputColor2,
          color3: this.form.outputColor3, color4: this.form.outputColor4,
           expire: expire }
      } else if (this.command.code == 'handGroup') {
        let d = new Date(this.form.expire)
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0)
        args = {
          areaId: this.form.areaId,
          no: this.form.groupNo,
          bright1: this.form.outputPower1,
          bright2: this.form.outputPower2,
          bright3: this.form.outputPower3,
          bright4: this.form.outputPower4,
          color1: this.form.outputColor1,
          color2: this.form.outputColor2,
          color3: this.form.outputColor3,
          color4: this.form.outputColor4,
          expire: expire
        }
      } else if (this.command.code == 'setAlarmRange') {
        args = {
          vHigh: this.form.vhigh,
          vLow: this.form.vlow,
          cHigh: this.form.chigh,
          cLow: this.form.clow,
          tHigh: this.form.thigh,
          tLow: this.form.tlow,
          leac: this.form.leac,
          leav: this.form.leav,
          interval: this.form.interval
        }
      } else if (this.command.code == 'setRelayLevel') {
        args = { level: this.form.level }
      } else if (this.command.code == 'setAlarmStatus') {
        args = {
          voltage: this.form.voltageAlarm,
          current: this.form.currentAlarm,
          temp: this.form.tempAlarm,
          gyro: this.form.gyroAlarm,
          leakc: this.form.leacAlarm,
          leakv: this.form.leavAlarm
        }
      } else if (this.command.code == 'setLeakageAlarmEnable') {
        args = { enable: this.form.leakageAlarm, check: this.form.checkTime, count: this.form.uploadCount }
      } else if (this.command.code == 'setPlanType') {
        args = { ch: this.form.channel, type: this.form.planType }
      } else if (this.command.code == 'getPlanType') {
        args = { ch: this.form.channel }
      } else if (this.command.code == 'setOutputPowerLimit') {
        args = { ch: this.form.channel, min: this.form.min, max: this.form.max }
      } else if (this.command.code == 'getOutputPowerLimit') {
        args = { ch: this.form.channel }
      } else if (this.command.code == 'setOutputVoltageCompensation') {
        args = { ch: this.form.channel, v: this.form.voltage }
      } else if (this.command.code == 'getOutputVoltageCompensation') {
        args = { ch: this.form.channel }
      } else if (this.command.code == 'setSigFlag') {
        args = { ch: this.form.channel, on: this.form.sigleOn, off: this.form.sigleOff, brightOn: this.form.sigleOnBright, brightOff: this.form.sigleOffBright }
      } else if (this.command.code == 'getSigFlag') {
        args = { ch: this.form.channel }
      } else if (this.command.code == 'setDayPlan1') {
        args = { timeId: this.form.timeId }
      } else if (this.command.code == 'setDayPlan2') {
        args = { timeId: this.form.timeId }
      } else if (this.command.code == 'handControl') {
        args = { open: this.form.open, enable: true, expire: new Date(this.form.expire).format('yyyy-MM-dd HH:mm:ss'), point: false }
      } else if (this.command.code == 'getYearPlan') {
        let date = new Date(this.form.date)
        args = { single: this.form.single, month: date.getMonth() + 1, day: date.getDate() }
      } else if (this.command.code == 'setCurrentRate') {
        args = { k: this.form.currentRate }
      } else if (this.command.code == 'update') {
        // console.log('args saved', this.form.content)
        args = { version: 3, flag: 0, len: this.form.packLen, content: this.form.content }
      } else if (this.command.code == 'setSigFlag') {
        args = { on: this.form.sigleOn, off: this.form.sigleOff }
      } else if (this.command.code == 'setNetwork') {
        args = { host: this.form.host, port: this.form.port, heart: this.form.heart }
      } else if (this.command.code == 'setLoraWork') {
        args = { channel: this.form.channel, speed: this.form.speed }
      } else if (this.command.code == 'getLatLngArgs') {
        args = { channel: this.form.channel }
      } else if (this.command.code == 'setLatLngArgs') {
        args = {
          channel: this.form.channel,
          enable: this.form.enable,
          list: this.form.gisControlList
        }
      } else if (this.command.code == 'setLeakageRate') {
        args = { ll: this.form.ll, lh: this.form.lh, lx: this.form.lx }
      } else if (this.command.code == 'setComArgs') {
        args = { com: this.form.comname, type: this.form.comtype, buad: this.form.combuad, data: this.form.comdata, parity: this.form.comparity, stop: this.form.comstop }
      } else if (this.command.code == 'getComArgs') {
        args = { com: this.form.comname }
      } else if (this.command.code == 'set433Id') {
        args = { id: this.form.id433 }
      } else if (this.command.code == 'send433Adjust') {
        args = { id: this.form.id433, range: this.form.range }
      } else if (this.command.code == 'set433Args') {
        args = {
          delay1: this.form.delay1,
          delay2: this.form.delay2,
          senseEn: this.form.senseEn,
          senseLevel: this.form.senseLevel,
          txReqEn: this.form.txReqEn,
          txReqCnt: this.form.txReqCnt,
          txDuty: this.form.txDuty,
          txSleep: this.form.txSleep,
          rxReqEn: this.form.rxReqEn,
          txPower: this.form.txPower,
          channel: this.form.channel,
          range: this.form.range
        }
      } else if (this.command.code == 'setUnionParam') {
        args = {
          rfsn: this.form.rfsn,
          channel: this.form.channel,
          txpower: this.form.txpower,
          txen: this.form.txen,
          repeat: this.form.repeat,
          period: this.form.period,
          range: this.form.range,
          rxen: this.form.rxen,
          mode: this.form.mode,
          keep1: this.form.keep1,
          keep2: this.form.keep2,
          sensor: this.form.sensor
        }
      } else if (this.command.code == 'ftp') {
        args = { host: this.form.host, port: this.form.port, file: this.form.file, user: this.form.user, pswd: this.form.pswd1, len: this.form.len, crc: this.form.crc }
      } else if (this.command.code == 'setLuxOpenValue') {
        args = { lux: this.form.lux }
      } else if (this.command.code == 'adjustChipBase') {
        if (this.form.voltage < 175 || this.form.voltage > 245) {
          this.$Message.warning(this.showLang('save.light.range.v', 175, 245))
          return
        }
        if (this.form.current < 0.1 || this.form.current > 2) {
          this.$Message.warning(this.showLang('save.light.range.i', 0.1, 2))
          return
        }
        if (this.form.power < 10 || this.form.power > 500) {
          this.$Message.warning(this.showLang('save.light.range.p', 10, 500))
          return
        }
        args = { ch: this.form.channel, v: this.form.voltage, c: this.form.current, p: this.form.power }
      } else if (this.command.code == 'adjustChipVoltage') {
        args = { ch: this.form.channel, v: this.form.voltage }
      } else if (this.command.code == 'adjustChipCurrent') {
        args = { ch: this.form.channel, v: this.form.current }
      } else if (this.command.code == 'adjustChipPower') {
        args = { ch: this.form.channel, v: this.form.power }
      } else if (this.command.code == 'getChipPower') {
        args = { ch: this.form.channel }
      } else if (this.command.code == 'getChipCurrent') {
        args = { ch: this.form.channel }
      } else if (this.command.code == 'getChipVoltage') {
        args = { ch: this.form.channel }
      } else if(this.command.code == 'setAPN'){
        args = { apn: this.form.apnUrl, user: this.form.apnUser, pswd: this.form.apnPswd };
      } else if(this.command.code == 'setVoltageOutputArgs') {
        args = {flag: this.form.voltageOutputArgs}
      } else if(this.command.code == 'setPWMOutputArgs') {
        args = {polar: this.form.pwmPolar, frq: this.form.pwmFrequence }
      } else {
        args = {}
      }
      this.$emit('saved', { code: this.command.code, paramsCode: this.command.paramsCode, name: this.command.name, args })
      // this.showModal = false
      this.form.pswd = ''
    },
    ok: function() {
      // console.log('args saved before 1', this.command.code)
      if (this.command.code == 'update') {
        if (this.$refs.fileInput.files.length == 0) {
          this.$Message.warning(this.showLang('save.upgrade.file'))
          return
        }
        if (this.form.pswd == '') {
          this.$Message.warning(this.showLang('save.batch.pswd'))
          return
        }
        this.$store.dispatch('auth/checkPswd', { pswd: this.form.pswd }).then(res => {
          if (res.code == 0) {
            var reader = new FileReader() //实例化文件读取对象
            reader.readAsDataURL(this.$refs.fileInput.files[0]) //将文件读取为 DataURL,也就是base64编码
            let that = this
            reader.onload = function(ev) {
              //文件读取成功完成时触发
              var dataURL = ev.target.result //获得文件读取成功后的DataURL,也就是base64编码
              // document.querySelector("img").src = dataURL; //将DataURL码赋值给img标签
              that.form.content = dataURL.replace('data:application/octet-stream;base64,', '')
              // that.$emit('saved', {code: that.code, name: that.name, packLen: that.form.packLen, args: dataURL})
              // that.argsSaved({
              //   code: that.code,
              //   name: that.name,
              //   args: { len: that.form.packLen, content: dataURL },
              // });
              // console.log('args saved before', that.form.content)
              that.createCmd()
              // that.loading = false;
              // that.showModal = false;
              that.$refs.fileInput.value = ''
            }
          }
        })
        return
      }
      if (this.command.code == 'openSingle' || this.command.code == 'closeSingle') {
        let d = new Date(this.form.expire)
        if (d < new Date()) {
          this.$Message.warning(this.showLang('com.time.future.choose'))
          return
        }
      }
      if (this.command.code == 'setRelayLevel') {
        if (this.form.level < 1 || this.form.level > 15) {
          this.$Message.warning(this.showLang('com.ins.relay.level.between', 1, 15))
          return
        }
      }
      if (this.command.code == 'setLuxOpenValue') {
        if (this.form.lux < 0 || this.form.lux > 65535) {
          this.$Message.warning(this.showLang('com.ins.light.val.between', 0, 65535))
          return
        }
      }
      if (this.command.code == 'setDayPlan1' || this.command.code == 'setDayPlan2') {
        // console.log('get time id', this.form.timeId)
        if (!this.form.timeId) this.form.timeId = 0
        if (this.form.timeId <= 0) {
          this.$Message.warning(this.showLang('save.light.plan.need'))
          return
        }
      }
      if (this.command.code == 'handControl') {
        let d = new Date(this.form.expire)
        if (d < new Date()) {
          this.$Message.warning(this.showLang('com.time.future.choose'))
          return
        }
      }
      if (this.command.code == 'setNetwork') {
        if (this.form.port < 0 || this.form.port > 65535) {
          this.$Message.warning(this.showLang('com.save.group.port.code', 0, 65535))
          return
        }
      }
      if (this.command.code == 'setLoraWork') {
        if (this.form.channel < 0 || this.form.channel > 100) {
          this.$Message.warning(this.showLang('com.save.group.ch.code', 0, 100))
          return
        }
      }
      if (this.command.code == 'setLeakageAlarmEnable') {
        if (this.form.checkTime < 15 || this.form.checkTime > 255) {
          this.$Message.warning(this.showLang('com.save.err.checkTime', 15, 255))
          return
        }
        if (this.form.uploadCount < 1 || this.form.uploadCount > 5) {
          this.$Message.warning(this.showLang('com.save.err.uploadCount', 1, 5))
          return
        }
      }
      if (this.command.code == 'setLeakageRate') {
        if (parseInt(this.form.ll) < 0) {
          this.$Message.warning(this.showLang('com.lighting.minor.leakage.pass', 0))
          return
        }
        if (parseInt(this.form.lh) < parseInt(this.form.ll)) {
          this.$Message.warning(this.showLang('com.lighting.minor.leakage.pass1'))
          return
        }
        if (parseInt(this.form.lx) < parseInt(this.form.lh)) {
          this.$Message.warning(this.showLang('com.lighting.serious.leakage.pass1'))
          return
        }
      }
      if (this.needCheck) {
        if (this.form.pswd == '') {
          this.$Message.warning(this.showLang('save.batch.pswd'))
          return
        }
        this.$store.dispatch('auth/checkPswd', { pswd: this.form.pswd }).then(res => {
          if (res.code == 0) {
            this.createCmd()
          }
        })
      } else {
        this.createCmd()
      }
    },
    cancel() {
      this.showModal = false
    }
  }
}
</script>
<style scoped>
.set-table tr td {
  border: solid 1px gray;
  text-align: center;
  white-space: nowrap;
}
.set-table {
  width: 100%;
}
.time-span {
  display: flex;
}
.min-width {
  width: 70px;
  flex: none;
  margin: 0;
}
.ch-width {
  width: 120px;
  flex: none;
  margin: 0 10px;
  display: flex;
}
.ch-width span {
  width: 25px;
  flex: none;
}
.ch-width div {
  width: 40px;
  flex: auto;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>
